import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../../../components/container'
import Layout from '../../../components/layout'
import banner from '../../../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
  min-height: 750px;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Text = styled.p``

const CursoMecanicaAviao = () => (
  <Layout title='Curso de Mecânica de Avião' page='educacao/cursos/mecanica-de-aviao'>
    <Banner />
    <Content>
      <Title>Curso de Mecânica de Avião</Title>

      <Text>
        Todo avião necessita de inspeção e manutenção constantes, sendo que estes
        trabalhos sãonormatizados e fiscalizados pela ANAC (Agência Nacional de Aviação
        Civil). Para que ummecânico de avião possa exercer suas atividades, deve ser
        homologado pela referida agência deuma a três possíveis homologações:
        Grupo-Motopropulsor – GMPGrupo-Célula- CELGrupo-Aviônicos – AVIO Mecânico de Avião
        que possui as homologações de GMP e CEL é responsável por todosos componentes
        mecânicos e estruturais de uma aeronave, incluindo os motores, caixas
        deengrenagens, controles de vôo, trens de pouso, sistema hidráulico, ar
        condicionado, sistemaanti-gelo e sistemas de combustível. O mecânico deve
        examinar, testar e reparar todos oscomponentes listados acima.Já o Mecânico de
        Avião que possui a homologação de AVI é responsável por todos osinstrumentos da
        cabine de comando usados para operar a aeronave, incluindo equipamentosde radar,
        comunicação, navegação, comandos eletrônicos de vôo, bem como todo o sistema
        degeração elétrica e distribuição na aeronave.O profissional inicia sua carreira
        como auxiliar, acompanhando os mecânicos na realização dostrabalhos de inspeção,
        reparo, troca de componentes e na liberação de aeronaves para retornoao vôo. Após
        o período de 3 (três) anos, tempo necessário para adquirir experiência em
        umaaeronave da empresa na qual trabalha, o mecânico auxiliar fará a prova prática
        com um Inspetorda ANAC, que o aprovará nas especialidas que foram exercidas até a
        data da prova. A partirdeste momomento o auxiliar passa a ser um Técnico de
        Manutenção de Aeronave e estaráhabilitato a todas as responsabilidades que este
        trabalho exije, e irá analisar, diagnosticar ereparar os mais serios problemas que
        aparecerão ao longo de sua complexa carreira.
      </Text>

      <Text>
        O Aeroclube do RN está habilitado pela ANAC a ministrar o curso de manutenção de
        Aeronaves, habilitação célula.
      </Text>

      <Text>O curso é dividido em quatro módulos: básico, célula.</Text>
      <Text>Detalhes:</Text>
      <Text>
        Básico – Neste módulo você aprenderá o necessário para iniciar os módulos de
        especialização.Mas não pense que é tão básico assim, o módulo é dividico em 13
        capítulos, onde você terá matérias como peso e balancemento de aeronaves, passando
        por fundamentos de motores egeradores elétricos, aerodinamica entre outros.
      </Text>
      <Text>Tempo mínimo do módulo: 06 meses</Text>
      <Text>
        CEL (Célula) – Esta é a especialidade que concentra os sistemas de pressurização,
        arcondicionado, pneumático, sistemas hidráulicos. Também é nesta habilitação que
        você vai podertrabalhar na estrutura de aviões e helicópteros em geral.
      </Text>
      <Text>Tempo mínimo do módulo: 11 meses</Text>
      <Text>
        Requisitos para se inscrever: Ter no mínimo 18 anos, a serem completados até o
        final do curso; ensino médio completo com o certificado de conclusão reconhecido
        pelo MEC.
      </Text>
    </Content>
  </Layout>
)

export default CursoMecanicaAviao
